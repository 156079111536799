import React,{useEffect, useState, Component} from 'react';
import Logo from '../../../Images/Logo.png'
import '../../BodyStyle/styles.scss'


function Dialog() {

const closeDialog=()=>{

  document.getElementById("dialog").style.display="none"
  document.getElementById("dialog").style.opacity=0
  document.getElementById("products").style.opacity=1
  document.getElementById("prodandcat").style.opacity=1
 
  document.body.style.overflow="auto"
 
}


  return (

<div className='Dialog' id="dialog">
    <div style={{textAlign:'center'}}>
         <img id="dialogImg" src={Logo}/>
    </div>
    <div style={{textAlign:'center', marginTop:'25px', fontWeight:'bold'}}>Vielen Dank! Wählen Sie bitte ein Produkt aus dem Shopbereich aus.</div>

      <div className='DialogBtn' onClick={closeDialog}>OK</div>
</div>


  );
}

export default Dialog;
