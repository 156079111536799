import {React, useEffect, useState, Component, useRef} from "react";
import './primeStyle.scss'
import Logo from '../pict/logo.png'
import Logo_x2 from '../pict/logo_Icon_x2.png'
import Flag from '../pict/flag.png'
import Cart from '../pict/shopping.png'


import SearchIcon from '@mui/icons-material/Search';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';


function HeadNeftl(){

    const head=useRef(null)





    return(
        <div id="primehead" className="prime-head"> 

        <div className="prime-head-main">
        
        <div className="prime-nav-left">
            
        <div className="prime-logo">
            <img src={Logo}/>
           
        </div>
         
        
        <div className="prime-location">
            <div>Lieferung an Düsseldorf</div>
            <span>Standort aktualisieren</span>
        </div>
       
        </div>

        <div className="prime-nav-search">
            
        <div className="prime-nav-search-left">Prime Video <span className="prime-arow" role="presentation"></span></div>
        <div className="prime-nav-search-center">Suche Amazon.de <img src={Logo_x2}/></div>
        <div className="prime-nav-search-btn"><SearchIcon style={{fontSize:'25px'}}/></div>
        
        </div>

        <div className="prime-nav-right">
        <div className="prime-nav-right_container">
        <div className="prime-nav-right-language"><img src={Flag}/>DE<span className="prime-arow primeLanguage" role="presentation"></span></div>
        <div className="prime-nav-right-login">Hallo, Benutzer <span>Konto und Listen</span></div>
        <div className="prime-nav-right-login options">Warenrücksendungen <span>und Bestellungen</span></div>
        <div className="prime-shoppingcart"><img src={Cart}/><span>Einkaufs&shy;wagen</span></div>
     
        </div>
        </div>

        </div>

     

        <div className="prime-head-nav">
            <div className="prime-head-nav_container">
            <div id="prime-head-nav-all"><MenuOutlinedIcon/><span>Alle</span></div>
            <NavLinks/>
            </div>
        </div>

    
   


        </div>
    )



    function NavLinks(){
        const arr=["Prime Video", "Erneut kaufen", "Browserverlauf", "Kindle Bücher", "Amazon Basics", "Küche, Haushalt & Wohnen",, "Gutscheine", "Kundenservice", "Bücher", "Geschenkideen", "Angebote", "Baumarkt", "Neuerscheinungen", "Bestseller"]
        return(
            arr.map((item,index)=>{
                return(
                    <div className="prime-head-nav-element" key={index}>{item}</div>
                )
            })
        )
    }
 

}

export default HeadNeftl;