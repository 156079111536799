import {React, useEffect, useState, Component, useRef, Fragment} from "react";
import '../styles.scss'
import { register } from 'swiper/element/bundle';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TikTokFooter from "../../Foot/TikTokFooter";
import { Link, useNavigate, redirect } from "react-router-dom";
import Data from '../../../../data/tiktok/data.json'

// Icons

import FavoriteIcon from '@mui/icons-material/Favorite';
import Comments from './assets/icons/content_icons/comment.svg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import TextsmsIcon from '@mui/icons-material/Textsms';

import dummy from './assets/icons/noimage.png'

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


register();
function TiktokContent(){

  const navigate=useNavigate()

    const timeLine=useRef(0)
    const dialogF=useRef(0)   
    
    const videoLoader=useRef(null)


    const showLoader=()=>{
      videoLoader.current.style.display="flex"
    }
      
    const hideLoader=()=>{
      videoLoader.current.style.display="none"
     
    }
    
    function PlayVideos(){
        const content=document.querySelectorAll('.tiktok_contentVideo')
        let prevVideoContent=null

        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
              prevVideoContent=target
             
              if(prevVideoContent!=null){
                
                prevVideoContent.pause()
              }
              
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                target.load()
                target.play()
                contentTimeUpdate(target)
            
                target.currentTime=0
              
      
              
            
                
              }
              
            })
           
          }

          
        const options={

            threshold: 0.6,
           
            }

        const observer=new IntersectionObserver(callback, options)
        
        content.forEach((item)=>{
            //item.muted=true
            observer.observe(item)
            item.addEventListener('waiting',showLoader)
            item.addEventListener('canplay',hideLoader)
        })
    } 

   
    function contentTimeUpdate(item){
       let time=0;
       let videoDuration=0
     
       const videoTime=timeLine.current
       
       
       item.addEventListener('timeupdate', function(){
       
         videoDuration=Math.round(item.duration)
         time=Math.round(item.currentTime)
       
      
         videoTime.style.width=(time*100)/videoDuration + "%"
         if(time>=videoDuration){
           item.currentTime=0
           videoTime.style.width="0%"
           item.play()
          
         }
        })
    }


     useEffect(()=>{
      
        const swiperEl = document.querySelector('swiper-container');
        document.body.style.overflow = 'hidden';

        swiperEl.swiper.on('reachEnd',()=>{
          swiperEl.swiper.allowSlideNext=false
        })
    
        swiperEl.swiper.on('fromEdge', () => {
         
          swiperEl.swiper.allowSlideNext = true; 
        });

        
           
     },[])

    const launch=()=>{
      
        document.getElementById("tiktok-playContent-Dialog").style.display="none"
      document.body.style.overflow = 'auto';
      PlayVideos()
    }

    return(
      <>

<Box className="tiktok_video_loader" ref={videoLoader}>
      <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
    </Box>
      <div className="tiktok-playContent-dialog" id="tiktok-playContent-Dialog">
      <div className="tiktok-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="tiktok-playContent_btn-container"><div className="tiktok-playContent_btn" onClick={launch}>Weiter</div></div>
    </div>
        <div className="tiktok-content">

        

            <swiper-container direction="vertical"  slides-per-view="1" style={{height:'95%'}}>
         <ContentVid/>
          </swiper-container>
       
          <div className="tiktok_video-content_timeline-container"><div className='tiktok_video-content_timeline' ref={timeLine}></div></div>
          
        </div>
       
        </>
    )


    function ContentVid(){
        
       return Data.data.map((item,index)=>{
        return(
            <Fragment key={index}>
            <swiper-slide >
       
            <div className="tiktok_video-content">
            <video preload="none" className="tiktok_contentVideo" src={item._attributes.url} playsInline/>
          
            <div className="tiktok_contentVideo_btns-container">
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={dummy}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:0.9}}/><div>{item._attributes.likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:0.9, transform:'scaleX(-1)'}}/>{item._attributes.rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={dummy}/></div></div>

            </div>
           

            </div>
            </swiper-slide>
            </Fragment>
        )
       })
          
    }


}

export default TiktokContent;
