import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import Products from './Products';
import Sortiment from './Sortiment';
import '../../BodyStyle/styles.scss'


function Body() {

useEffect(()=>{
    
   
},[])

  return (
    <div className="MainBody">
      <Sortiment/>
     <div className='ProductsandCategory'>
     <Category/>
     <div className="Products" id="products">
      <Products/>
      </div>
      
     </div>


</div>
  );
}

export default Body;
