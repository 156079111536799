import {React, useEffect, useState, Component, useRef} from "react";
import '../styles.scss'

import Ad1 from '../../ads/test.mp4'
import Ad2 from '../../ads/test2.mp4'
import Ad3 from '../../ads/test3.mp4'
import Ad4 from '../../ads/test4.mp4'
import Ad5 from '../../ads/test5.mp4'
import Ad6 from '../../ads/test6.mp4'
import {useParams, useSearchParams  } from 'react-router-dom';
import DataAds from '../../../../data/prime/ads.json'
import { useSelector, useDispatch } from 'react-redux'
import {checkAd} from '../../../../app/checkSlice'
import { yellow } from "@mui/material/colors";
import Intro from './assets/primeintro.mp4'


import Volume from '../../pict/volume.svg'
import Subtit from '../../pict/subtit.svg'
import Settings from '../../pict/settings.svg'
import Arrow from '../../pict/arrow.svg'
import Close from '../../pict/close.svg'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function AdMultiblock({onAdBlockEnd, adToggle}){


    const [videoPlay, setVideoPlay]=useState(false)
    const [videoTime, setVideoTime]=useState(0)

    const adVideo=useRef(null)
    const videoTrack=useRef(null)
    const timeLine=useRef(null)
    const timer=useRef(null)
    const timestamp=useRef(null)
    const adVideoIntro=useRef(null)
    const adTime=useRef(null)
    const AdVolume=useRef(null)
    const advolumeContainer = useRef(null)

    const primeTimeOutRef = useRef(null)
    const primeadControllsMain = useRef(null)
    const primeadControlls=useRef(null)
    const primeadControllsTop=useRef(null)
    const primeadControllsBlock=useRef(null)

    
    let videoDuration // main video duration

    const [searchParams] = useSearchParams();
    const ad1 = searchParams.get('ad1');
    const ad2 = searchParams.get('ad2');
    const ad3 = searchParams.get('ad3');
  
    const advideoLoader=useRef(null)
    
    
    const [videoTimeSec, setVideoTimeSec]=useState("0")
    const [videoTimeMin, setVideoTimeMin]=useState("0")

    const [advolumeSwitch, setadvolumeSwitch]=useState(true) 

    const [currenVideo, setCurrenVideo]=useState(0)
    const [remainVideo, setRemainVideo]=useState(0)
   

    let adIndex=0 //index ad videoblock

    const adchecked=useSelector(state=>state.check.value)
    const dispatch=useDispatch()
    
  

     useEffect(()=>{
      
       
        
        
        if(!adToggle){
       // const ads=handleSetRandomAd()
        const ads=handleSetPreAd()
        introStart(ads)
        //VideoAdsDone(ads)
      }
        if(adToggle){
        //const ads=handleSetRandomAdSecond()
        const ads=handleSetMidAd()
        introStart(ads)
        timestamp.current.style.opacity=0
       // VideoAdsDone(ads)
       
      }


      if(adVideo){
        adVideo.current.addEventListener('loadedmetadata',handlegetVideoDuration)
        adVideo.current.addEventListener('timeupdate',handleTimeLineUpdate)
        
      }

      return()=>{
        adVideo.current.removeEventListener('loadedmetadata',handlegetVideoDuration)
      }
        
       
    
      
     },[adToggle])


     const handlegetVideoDuration=(e)=>{
      
    
      setVideoTimeMin(Math.round((e.target.duration%3600)/60))
      setVideoTimeSec(Math.round(e.target.duration%60))
      if(isNaN(videoTimeSec)){
        setVideoTimeSec(0)
        setVideoTimeMin(0)
       
      }
     
        timestamp.current.style.opacity=0
        e.target.play()
      
    
      
    }
     
     const handleTimeLineUpdate=()=>{
        timeLine.current.style.width=(Math.round(adVideo.current.currentTime)*100)/videoDuration + "%"
    }

    const updateTime=(e)=>{
      let duration=Math.round(e.target.duration)-Math.round(e.target.currentTime)

      setVideoTimeSec(Math.round(duration%60))
      setVideoTimeMin(Math.floor((duration%3600)/60))
      //adTime.current.style.opacity=1
      if(isNaN(e.target.currentTime) || isNaN(e.target.duration)){
        timestamp.current.style.opacity=0
       
      }
      else{
        timestamp.current.style.opacity=1
      
      }
      
     
    }

 


    // ad loop start
   async function handleAdsStart(arr){

     return new Promise((resolve,reject)=>{
       

      timeLine.current.style.transition='all 1s'
          adVideo.current.src=arr[adIndex]
          setCurrenVideo(adIndex+1)
          
          
        
          adVideo.current.play()
         // timestamp.current.style.opacity=1
          
          
       adVideo.current.addEventListener('ended',function(){
        timeLine.current.style.transition='all 0s'
        timestamp.current.style.opacity=0
        if(adIndex>=arr.length-1){
          timestamp.current.style.opacity=0
          resolve(false)
          adVideo.current.src=""
        }
        else{
          
          
          adIndex++
          setCurrenVideo(adIndex+1)
         
          adVideo.current.src=arr[adIndex]
        //  adVideo.current.play()
          setTimeout(() => {
            timeLine.current.style.transition='all 1s'
          }, 1000);
        }
       })

      
        
        
      })
      
    }


    const showLoader=()=>{
   
      advideoLoader.current.style.display="flex"
      
   
    }
      
    const hideLoader=()=>{
     advideoLoader.current.style.display="none"
     
  
      
     
    }


    const introStart=(ads)=>{
      adVideoIntro.current.style.display="block"
   
   
        adVideoIntro.current.play()
       


        adVideoIntro.current.addEventListener('ended',()=>{
          introEnd(()=>VideoAdsDone(ads));
        },{once:true})
   
      
    }

    const introEnd=(callback)=>{
   
      adVideoIntro.current.currentTime=0
      adVideoIntro.current.style.display="none"
      
     callback()
    }


    const handeEndAd=(status)=>{
     
      
      onAdBlockEnd()
      
    }

    function VideoAdsDone(arr){
      handleAdsStart(arr)
      .then(result=>{
        
        handeEndAd(result)

        
       return result;
      })
    
    }

    
    

    // ads randomize
     function handleSetRandomAd(){ 
      let adTemp 
      const adblock=[Ad1] 
    
        for(let i=adblock.length-1;i>0; i--){
          let j = Math.floor(Math.random()*(i+1));
          adTemp=adblock[i]
          adblock[i]=adblock[j]
          adblock[j]=adTemp
        }
    
        return adblock

        
        

        
    }
     function handleSetRandomAdSecond(){ 
      let adTemp 
      const adblock=[Ad2,Ad3] 
    
        for(let i=adblock.length-1;i>0; i--){
          let j = Math.floor(Math.random()*(i+1));
          adTemp=adblock[i]
          adblock[i]=adblock[j]
          adblock[j]=adTemp
        }
    
        return adblock

        
        

        
    }


    function handleSetPreAd(){  // ohne Random
     
      const adblock=[DataAds.ads[ad1].url]  // Test Mod, bei PreRoll nur 1 Ad! Ad5 das längste video
      setRemainVideo(adblock.length)
      
    
        return adblock

        
        

        
    }

    function handleSetMidAd(){  // ohne Random
     
      const adblock=[DataAds.ads[ad2].url,DataAds.ads[ad3].url]  // Test Mod, bei PreRoll nur 1 Ad! Ad5 das längste video
      setRemainVideo(adblock.length)
      
    
        return adblock

        
        

        
    }

    
     const handlePrimePlayPause=(e)=>{
       
      if(adVideo.current.paused){
        adVideo.current.play()
        setVideoPlay(false)
    }
    else{
       adVideo.current.pause()
        setVideoPlay(true)
    }
     }


     const handlePrimePlayPauseBtn=()=>{
       
        if(adVideo.current.paused){
            adVideo.current.play()
            setVideoPlay(false)
        }
        else{
           adVideo.current.pause()
            setVideoPlay(true)
        }
        
   
       
     }

     const changeAdVolume=(e)=>{
      let VidVolume=parseFloat((e.target.value)/100).toFixed(1)
      console.log(VidVolume)
      if(adVideoIntro.current!=undefined){
        adVideoIntro.current.volume=VidVolume
      }
      if(adVideo.current!=undefined){
        adVideo.current.volume=VidVolume
       
      }
     
  
    }


    function handleShowPrimePanel(){
      let primePanel=true

      if(primeTimeOutRef.current){
       clearTimeout(primeTimeOutRef.current)
      
      }
    
       if(primeadControllsMain.current){
      
       primeadControlls.current.style.opacity=1
    
       primeadControllsTop.current.style.opacity=1
       
  
       
      

       primeTimeOutRef.current=setTimeout(function(){
           primePanel=false
           hidePrimePanel(primePanel)
           primeTimeOutRef.current=null
     
       }, 2000);
       
   }  
    

    }
  
 function hidePrimePanel(primePanel){

   if(!primePanel){

   
   primeadControlls.current.style.opacity=0 //0
   
   primeadControllsTop.current.style.opacity=0 //0

  advolumeContainer.current.style.display="none"
  setadvolumeSwitch(!advolumeSwitch)
  
   

   }
 }


 
 const showHideVolume=()=>{
  setadvolumeSwitch(!advolumeSwitch)

  if(advolumeSwitch==true){
      advolumeContainer.current.style.display="flex"
  }
  else{
      advolumeContainer.current.style.display="none"
  }
}

     


    return(
      <div className="primead-video-container" ref={primeadControllsMain} id="video-container-adblock" onMouseMove={handleShowPrimePanel}>

<Box className="primead_video_loader" ref={advideoLoader}>
      <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
    </Box>

      <div className="primead-video-controlls-top"  ref={primeadControllsTop}>
   

      <div id="primead-video-controlls-top-back" >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-mirrorinrtl="true" className="ltr-4z3qvp e1svuwfo1" data-name="ArrowLeft" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M6.41421 10.9998L21 10.9998L21 12.9998L6.41421 12.9998L11.7071 18.2927L10.2929 19.7069L3.29289 12.7069C2.90237 12.3164 2.90237 11.6832 3.29289 11.2927L10.2929 4.29272L11.7071 5.70694L6.41421 10.9998Z" fill="currentColor"></path></svg>
          </div>

          <div id="primead-video-controlls-top-mark">
              
              <div><img src={Subtit}/></div>
              <div><img src={Settings}/></div>
              <div id="primead-volume-logo_container" ref={AdVolume} onClick={showHideVolume}><img src={Volume}/></div>
              <div><img src={Arrow}/></div>
              <div><img src={Close}/></div>
              
              <div className="primead-volume_container" ref={advolumeContainer}>

              <Box sx={{ height: 'inherit' }}>
    <Slider
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: 'slider-vertical',
        },
        color:'white',
      }}
      orientation="vertical"
      defaultValue={100}
      onChange={changeAdVolume}
     
     
    />
  </Box>

              </div>
          </div>

      
          
      </div>


      <div className="prime-video-controlls" ref={primeadControlls}>


     

<div className="ad-prime-video-controlls-player">



<div className="prime-video-controlls-play PrimeLogoHover"  id="primeLogohoverplaypause">
{videoPlay ?  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-nfplayerPlay ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true"><path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path></svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-nfplayerPause ltr-4z3qvp e1svuwfo1" data-name="Pause" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 3C4.22386 3 4 3.22386 4 3.5V20.5C4 20.7761 4.22386 21 4.5 21H9.5C9.77614 21 10 20.7761 10 20.5V3.5C10 3.22386 9.77614 3 9.5 3H4.5ZM14.5 3C14.2239 3 14 3.22386 14 3.5V20.5C14 20.7761 14.2239 21 14.5 21H19.5C19.7761 21 20 20.7761 20 20.5V3.5C20 3.22386 19.7761 3 19.5 3H14.5Z" fill="currentColor"></path></svg>} 
</div>





</div>


</div>

      <div>
      <div className="primeAd-time-count_container" ref={timestamp}>
        <div className="primeAd-time-count-adText">Werbung</div>
      <div className="primeAd-Ad_time" ref={adTime}>{String(videoTimeMin).padStart(2,"0")}:{String(videoTimeSec).padStart(2,"0")}</div>
      <div className="prime-circle"></div>
      <div>{currenVideo} of {remainVideo}</div>

      </div>
      
      <video className="prime-main-vid vidIntro" src={Intro}  type="video/mp4" ref={adVideoIntro} onWaiting={showLoader} onCanPlay={hideLoader}></video>
      <video className="prime-main-vid"  type="video/mp4" ref={adVideo} onTimeUpdate={updateTime} onLoadedMetadata={handlegetVideoDuration} onWaiting={showLoader} onCanPlay={hideLoader}></video>
      </div>
      <div className="prime-filter-effect" ></div>
 
      <div className="prime-timeline-controls-block">

      <div className="prime-duration-container">
      <div className='prime-videoTrack' ref={videoTrack}>
     <div className='prime-timeline' ref={timeLine}></div>
     

      </div>
      
     </div>

  
  
   </div>

   </div>
    )




}

export default AdMultiblock;


