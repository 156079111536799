import {React, useEffect, useState, Component, useRef} from "react";
import '../styles.scss'
import Ad1 from '../../ads/test.mp4'
import Ad2 from '../../ads/test2.mp4'
import Ad3 from '../../ads/test3.mp4'
import Ad4 from '../../ads/test4.mp4'
import Ad5 from '../../ads/test5.mp4'
import Ad6 from '../../ads/test6.mp4'

import { useSelector, useDispatch } from 'react-redux'
import {checkAd} from '../../../../app/checkSlice'



function AdPreblock({onAdBlockEnd, adToggle}){


    const [videoPlay, setVideoPlay]=useState(false)
    const [videoTime, setVideoTime]=useState(0)

    const adVideo=useRef(null)
    const videoTrack=useRef(null)
    const timeLine=useRef(null)
    const timer=useRef(null)
    
    let videoDuration // main video duration

    let urlParams=document.location.search // read url params 
    let searchParams=new URLSearchParams(urlParams) //searchParams.get('m') //mode



    let adIndex=0 //index ad videoblock


  

     useEffect(()=>{
      
        const handlegetVideoDuration=()=>{
            videoDuration=Math.round(adVideo.current.duration)
            setVideoTime(Math.round(adVideo.current.duration)/60)
          
           
        }
        
        
        
        const ads=handleSetRandomAd()

        VideoAdsDone(ads)
      
       

      if(adVideo){
        adVideo.current.addEventListener('loadedmetadata',handlegetVideoDuration)
        adVideo.current.addEventListener('timeupdate',handleTimeLineUpdate)
        
      }

      return()=>{
        adVideo.current.removeEventListener('loadedmetadata',handlegetVideoDuration)
      }
        
       
      
      
     },[])


     const handleTimeLineUpdate=()=>{
        timeLine.current.style.width=(Math.round(adVideo.current.currentTime)*100)/videoDuration + "%"
    }



    // ad loop start
   async function handleAdsStart(arr){

     return new Promise((resolve,reject)=>{
        

      timeLine.current.style.transition='all 1s'
          adVideo.current.src=arr[adIndex]
          
          adVideo.current.play()
         
       adVideo.current.addEventListener('ended',function(){
        timeLine.current.style.transition='all 0s'
        if(adIndex>=arr.length-1){
          resolve(false)
        }
        else{
          adIndex++

          adVideo.current.src=arr[adIndex]
          adVideo.current.play()
          setTimeout(() => {
            timeLine.current.style.transition='all 1s'
          }, 1000);
        }
       })

      
        
        
      })
      
    }




    const handeEndAd=(status)=>{
      
     
      onAdBlockEnd()
      
    }

    function VideoAdsDone(arr){
      handleAdsStart(arr)
      .then(result=>{
        handeEndAd(result)

        
       return result;
      })
    
    }

    
    

    // ads randomize
     function handleSetRandomAd(){ 
      let adTemp 
      const adblock=[Ad1]  // Test Mod, bei PreRoll nur 1 Ad! Ad5 das längste video
    
        for(let i=adblock.length-1;i>0; i--){
          let j = Math.floor(Math.random()*(i+1));
          adTemp=adblock[i]
          adblock[i]=adblock[j]
          adblock[j]=adTemp
        }
    
        return adblock

        
        

        
    }
    
     const handlePrimePlayPause=(e)=>{
       
      if(adVideo.current.paused){
        adVideo.current.play()
        setVideoPlay(false)
    }
    else{
       adVideo.current.pause()
        setVideoPlay(true)
    }
     }


     const handlePrimePlayPauseBtn=()=>{
       
        if(adVideo.current.paused){
            adVideo.current.play()
            setVideoPlay(false)
        }
        else{
           adVideo.current.pause()
            setVideoPlay(true)
        }
        
   
       
     }


     


    return(
      <div className="prime-video-container" id="video-container-adblock">

      <div className="prime-video-controlls-top">


      <div id="prime-video-controlls-top-back" >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-mirrorinrtl="true" className="ltr-4z3qvp e1svuwfo1" data-name="ArrowLeft" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M6.41421 10.9998L21 10.9998L21 12.9998L6.41421 12.9998L11.7071 18.2927L10.2929 19.7069L3.29289 12.7069C2.90237 12.3164 2.90237 11.6832 3.29289 11.2927L10.2929 4.29272L11.7071 5.70694L6.41421 10.9998Z" fill="currentColor"></path></svg>
          </div>

      
          
      </div>


      <div className="prime-video-controlls">

     

<div className="ad-prime-video-controlls-player">



<div className="prime-video-controlls-play PrimeLogoHover" onClick={handlePrimePlayPauseBtn} id="primeLogohoverplaypause">
{videoPlay ?  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-nfplayerPlay ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true"><path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path></svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-nfplayerPause ltr-4z3qvp e1svuwfo1" data-name="Pause" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 3C4.22386 3 4 3.22386 4 3.5V20.5C4 20.7761 4.22386 21 4.5 21H9.5C9.77614 21 10 20.7761 10 20.5V3.5C10 3.22386 9.77614 3 9.5 3H4.5ZM14.5 3C14.2239 3 14 3.22386 14 3.5V20.5C14 20.7761 14.2239 21 14.5 21H19.5C19.7761 21 20 20.7761 20 20.5V3.5C20 3.22386 19.7761 3 19.5 3H14.5Z" fill="currentColor"></path></svg>} 
</div>





</div>


</div>

      
      <video className="prime-main-vid"  type="video/mp4" ref={adVideo}></video>

      <div className="prime-filter-effect" onClick={handlePrimePlayPause}></div>

      <div className="prime-timeline-controls-block">

      <div className="prime-duration-container">
      <div className='prime-videoTrack' ref={videoTrack}>
     <div className='prime-timeline' ref={timeLine}></div>
     

      </div>
      
     </div>

  
  
   </div>

   </div>
    )




}

export default AdPreblock;


