import React,{useEffect, useState, Component} from 'react';
import Logo from '../../Images/Logo.png'
import Pay from '../../Images/payback.png'
import './styles.scss';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Lupe from '../../Images/img/lupe.png'
import User from '../../Images/img/user.png'
import Menu from '../../Images/img/menu.png'
import Kalender from '../../Images/img/kalender.png'
import Herz from '../../Images/img/herz.png'
import Einkaufswagen from '../../Images/img/einkaufswagen.png'

function Head() {


  useEffect(()=>{
    let searchField=document.getElementById("searchfield")
    let firstlayer=document.getElementById("firstlayer")
    let content=document.getElementById("content")

    if(window.screen.width<=480){
      firstlayer.append(searchField)
    }

  },[])

  return (
    <div className="Head">
      <div id="firstlayer" className='FirstLayer'>

<div id="content" className='Content'>

        <div className='LogoImg' id="logoimg">
            <img id="menu" src={Menu}/>
            <img id="logo" src={Logo}/>

            <div id="mobileLogos">
            <img id="wagen" src={Einkaufswagen}/>
            <img id="user" src={User}/>
            </div>
            
        </div>

            <div className='HeadButtons' id="headbuttons">
               
                    <div className='Button'>Märkte & Angebote</div>
                    <div className='Button'>Lieferservice</div>
                    <div className='Button'>Rezepte & Ernährung</div>
                
            </div>

            <div className='Panel' id="panel">
                <div className='PanelItem'><HomeOutlinedIcon sx={{fontSize:'21px'}}/><span>|</span>rewe.de</div>
                <div className='PanelItem'><PersonOutlineOutlinedIcon sx={{fontSize:'21px'}}/><span>|</span>Anmelden</div>
                <div className='PanelItem'><LocationOnOutlinedIcon sx={{fontSize:'21px'}}/><span>|</span>PLZ 51063</div>
                <div className='PanelItem'><img className='PayBackImg' src={Pay}/></div>
            </div>

        </div>
        
      </div>


      <div className="Secondlayer">
        <div className="Content2">
        <div id="searchfield" className="SearchField"><span>Suche</span><img style={{width:'20px', marginRight: '10px'}} src={Lupe}/></div>
        <div className="OptionenBtn">
            <div className="OptionButton"><img style={{width:'20px'}} src={Kalender}/>Liefertermin wählen</div>
            <div className="OptionButton"><img style={{width:'20px'}} src={Herz}/>Favoriten</div>
            <div className="OptionButton"><img style={{width:'20px'}} src={Einkaufswagen}/>0,00 €</div>
        </div>
        
        </div>
      </div>

      <div className="Thirdlayer">
        <div className="Content3">
            <div id="leftbutton" >Sortiment ˅</div>
            <div className="FourButtons">Meine Produkte</div>
            <div className="FourButtons">Angebote</div>
            <div className="FourButtons">Osterwelt</div>
            </div>
      </div>

    </div>
  );
}

export default Head;
