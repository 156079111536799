import {React, useEffect, useState, Component, useRef, Fragment} from "react";
import '../styles.scss'
import { register } from 'swiper/element/bundle';
import {useParams } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TikTokFooter from "../../Foot/TikTokFooter";
import { Link, useNavigate, redirect } from "react-router-dom";
import Data from '../../../../data/tiktok/data.json'

// Icons

import FavoriteIcon from '@mui/icons-material/Favorite';
import Comments from './assets/icons/content_icons/comment.svg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import TextsmsIcon from '@mui/icons-material/Textsms';

import dummy from './assets/icons/noimage.png'

// Ad

import TopViewAd from "./ads/TopViewAd";

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

register();
function TiktokTopView(){

  const navigate=useNavigate()
  const {tiktokad}=useParams()

    const timeLine=useRef(0)
    const dialogF=useRef(0)
    const videoAd=useRef(null)

    const videoAdHead=useRef(null)
    const videoAdFoot=useRef(null)
    const videoAdBts=useRef(null)
    const weiterBtn=useRef(null)
    const btnsCont=useRef(null)

    const videoAdSlider=useRef(null)
    const videoAdContainer=useRef(null)

    const videoLoader=useRef(null)

    const videoEvents = [
      'loadstart', 'progress', 'suspend', 'abort', 'error', 'emptied', 
      'stalled', 'play', 'pause', 'loadedmetadata', 'loadeddata', 'waiting',
      'playing', 'canplay', 'canplaythrough',
      'ended', 'ratechange', 'durationchange', 'volumechange'
    ];

    const nonPlayingEvents = ['pause', 'stalled', 'waiting', 'suspend', 'error', 'abort'];

    const adTimeCheck=()=>{
      videoAd.current.addEventListener('timeupdate', timeTick)
      //videoAd.current.addEventListener('timeupdate', test)
    }

    const topViewEnd=()=>{
      videoAd.current.removeEventListener('timeupdate', timeTick)
      videoAd.current.addEventListener('canplay', handleCanPlay);
      document.querySelector('.tiktok-footer').style.opacity=1
      document.querySelector('.tiktok_head').style.opacity=1
      videoAdSlider.current.style.height='100dvh'
      videoAdBts.current.style.opacity=1
      videoAdHead.current.style.opacity=0
      videoAdFoot.current.style.opacity=0
    }

    const timeTick=()=>{
 
      if(Math.round(videoAd.current.currentTime)>=3){
        topViewEnd()

      }
    }



    const skipClick=()=>{
      const swiperEl = document.querySelector('swiper-container');
      swiperEl.swiper.slideNext()
      topViewEnd()
    }

    function handleCanPlay() {
      document.getElementById("tiktokindex").style.overflow="auto"
      videoAdContainer.current.style.pointerEvents="all"
      videoAdContainer.current.style.overflow="auto"
      adTimeCheck();
  }

  const showLoader=()=>{
   
    videoLoader.current.style.display="flex"
    
 
  }
    
  const hideLoader=()=>{
   videoLoader.current.style.display="none"
   

    
   
  }
    
    function PlayVideos(){
    
      videoAd.current.addEventListener('canplay', handleCanPlay)
      const swiperEl = document.querySelector('swiper-container');
      
      const content=document.querySelectorAll('.tiktok_contentVideo')


       
        let prevVideoContent=null

        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
              prevVideoContent=target
             
              if(prevVideoContent!=null){
               
               
                if(prevVideoContent.currentTime > 0 && !prevVideoContent.paused){
                  prevVideoContent.pause()
                }
                  
               
              }
              
              
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                target.load()
                target.muted=false
               
                target.currentTime=0
                target.play()
              
                contentTimeUpdate(target)

                target.addEventListener('waiting',showLoader)
                target.addEventListener('canplay',hideLoader)
            
                target.currentTime=0
              
                content.forEach((item,index)=>{
                  
                 if(index!=swiperEl.swiper.activeIndex){
                  item.muted=true
                  
                 }
                 
              })
                
            
                
              }
              
            })
           
          }

          
        const options={

            threshold: 0.6,
           
            }

        const observer=new IntersectionObserver(callback, options)
        
        content.forEach((item)=>{
          item.muted=true
          //item.play()
          //item.pause()
            observer.observe(item)

            
        
           
           
      
        })


        swiperEl.swiper.on('slideChange',()=>{
          if(swiperEl.swiper.activeIndex===1){
            topViewEnd()
          }
       
          
        })
       



    } 

   
    function contentTimeUpdate(item){
       let time=0;
       let videoDuration=0
     
       const videoTime=timeLine.current
       
       
       item.addEventListener('timeupdate', function(){
       
         videoDuration=Math.round(item.duration)
         time=Math.round(item.currentTime)
       
      
         videoTime.style.width=(time*100)/videoDuration + "%"
         if(time>=videoDuration){
           item.currentTime=0
           videoTime.style.width="0%"
           item.play()
          
         }
        })
    }


     useEffect(()=>{
      
     document.getElementById("tiktokindex").style.overflow="hidden"
     videoAdContainer.current.style.pointerEvents="none"
     videoAdContainer.current.style.overflow="hidden"

        const swiperEl = document.querySelector('swiper-container');
        document.body.style.overflow = 'hidden';

        swiperEl.swiper.on('reachEnd',()=>{
          swiperEl.swiper.allowSlideNext=false
        })
    
        swiperEl.swiper.on('fromEdge', () => {
         
          swiperEl.swiper.allowSlideNext = true; 
        });

        document.querySelector('.tiktok-footer').style.opacity=0
     
        document.querySelector('.tiktok_head').style.opacity=0

        videoAdBts.current.style.opacity=0
        btnsCont.current.style.opacity=1


        //videoAdContainer.current.style.pointerEvents="none"
      
       // PlayVideos()
      
         
     },[])

    const launch=()=>{
      
        document.getElementById("tiktok-playContent-Dialog").style.display="none"
      document.body.style.overflow = 'auto';
      PlayVideos()
    }

    return(
      <>
      
      <div className="tiktok-playContent-dialog" id="tiktok-playContent-Dialog">
      <div className="tiktok-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="tiktok-playContent_btn-container"><div className="tiktok-playContent_btn" ref={weiterBtn} onClick={launch} >Weiter</div></div>
    </div>
   
          <Box className="tiktok_video_loader" ref={videoLoader}>
      <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
    </Box>
        <div className="tiktok-content" ref={videoAdContainer}>

        

            <swiper-container  prevent-interaction-on-transition="true" direction="vertical"  slides-per-view="1" style={{height:'100dvh'}}>
            <swiper-slide style={{height:'100dvh'}} ref={videoAdSlider}>
          
              
            <TopViewAd videoID={tiktokad} videoRef={videoAd} headRef={videoAdHead} footRef={videoAdFoot} btnsRef={videoAdBts} skipAdClick={skipClick}/>
            </swiper-slide>
         <ContentVid/>
          </swiper-container>
       
          <div className="tiktok_video-content_timeline-container"><div className='tiktok_video-content_timeline' ref={timeLine}></div></div>
          
        </div>
       
        </>
    )


    function ContentVid(){
        
       return Data.data.map((item,index)=>{
    
         
        return(
            <Fragment key={index}>
            <swiper-slide >
            <div className="tiktok_video-content">
            <video  preload="none" className="tiktok_contentVideo" src={item._attributes.url} playsInline/>
          
            <div className="tiktok_contentVideo_btns-container" ref={btnsCont}>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={item._attributes.icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:1}}/><div>{item._attributes.likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:1}}/>{item._attributes.com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:1}}/>{item._attributes.book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:1, transform:'scaleX(-1)'}}/>{item._attributes.rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={item._attributes.icons}/></div></div>

            </div>
           

            </div>
            </swiper-slide>
            </Fragment>
        )
      
       })
          
    }


}

export default TiktokTopView;
