import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import '../../BodyStyle/styles.scss'
import data from '../../../data/dove.json'
import Herz from '../../../Images/img/herz.png'
import Wagen from '../../../Images/img/einkaufswagen.png'




function ProductsDove() {

  let prods=data.products;

  let urlParam=document.location.search;
  let searchParams=new URLSearchParams(urlParam)
  let show=searchParams.get("show")





useEffect(()=>{


  

  let showroom=document.createElement('div')
  showroom.className="Showroom"
  let showroomImg=document.createElement('div')
  showroomImg.className="ShowroomImg"
  showroomImg.id="ShowroomImgSonst"
  let showroomimg=document.createElement('img')
  let showroomLogo=document.createElement('img')
  showroomLogo.src="../../../../logos/rexonaLogo.png"
  showroomLogo.id="logoRexona"


  let showroomSlogan=document.createElement('div')
  

  showroomSlogan.id="sloganRexona"
  showroomSlogan.innerText="Rexona lässt dich nicht im Stich"


  showroomimg.src="../../../../rexona/1.jpg"
  let showroomText=document.createElement('div')
  showroomText.className="ShowroomText"
  let carousel=document.createElement('div')
  carousel.className="Carousel"
  let carouselButtons=document.createElement('div')
  carouselButtons.className="CarouselButtons"
  let prev=document.createElement('div')
  prev.className="Prev"
  prev.innerHTML="<"
  let next=document.createElement('div')
  next.className="Next"
  next.innerHTML=">"



  function createShowroom(){

  


    
    document.getElementById('prod9').after(showroom)
    
    
    showroom.append(showroomImg)
    showroomImg.append(showroomimg)

    showroomImg.append(showroomSlogan)
    

    showroomImg.append(showroomLogo)
    showroomImg.after(carousel)
    
    carousel.append(showroomText)
    //showroomText.innerHTML="Deine Zeit. Dein Deo"
    showroomText.after(carouselButtons)
    carouselButtons.append(prev)
    
    carouselButtons.append(next)
   
    
    let imgarr=new Map([
      ["Rexona Deo Creme Maximum Protection Clean Scent 45ml",'../../../../rexona/rexona6.png'],
      ["Rexona Deospray Cotton Dry Anti-Transpirant Compressed 75ml",'../../../../rexona/rexona7.png'],
      ["Rexona Deo Roll-On Shower Fresh Anti-Transpirant 50ml",'.../../../../rexona/rexona8.png'],
      ["Rexona Men Deospray Invisible on Black+White clothes 150ml",'../../../../rexona/rexona9.png'],
      ["Rexona Men Deo Roll-On Cobalt Dry Anti-Transpirant 50ml",'../../../../rexona/rexona10.png']
  ])
    
    
   imgarr.forEach((value,key,map)=>{

    
 
   
    let carouselProduct=document.createElement('div')
    carouselProduct.className="CarouselProduct ad_klicks"
    let carouselImgName=document.createElement('div')
    carouselImgName.className="CarouselImgName"
    let prodshowImg=document.createElement('div')
    prodshowImg.className="ProdShowImg"
    let prodshowimg=document.createElement('img')
    prodshowimg.src=value
    let prodshowName=document.createElement('div')
    prodshowName.className="ProdShowName"
    let carouselGerus=document.createElement('div')
    carouselGerus.className="CarouselGerus"
    let carouselFavorite=document.createElement('div')
    carouselFavorite.className="CarouselFavorite"
    let carouselfavoriteimg=document.createElement('img')
    carouselfavoriteimg.src=Herz
    let carouselBuy=document.createElement('div')
    carouselBuy.className="CarouselBuy"
    let carouselbuyimg=document.createElement('img')
    carouselbuyimg.src=Wagen
    let carouselSpan=document.createElement('span')


    carouselButtons.after(carouselProduct)
    carouselProduct.append(carouselImgName)
    carouselProduct.setAttribute('data-click-tracking','showroom')
    carouselImgName.append(prodshowImg)
    prodshowImg.append(prodshowimg)
    prodshowImg.after(prodshowName)
    prodshowName.innerHTML=key
    carouselImgName.after(carouselGerus)
    carouselGerus.append(carouselFavorite)
    carouselFavorite.append(carouselfavoriteimg)
    carouselFavorite.after(carouselBuy)
    carouselBuy.append(carouselbuyimg)
    carouselBuy.append(carouselSpan)
    carouselSpan.innerHTML="+"

    carouselProduct.onclick=function(){
    
    
      document.getElementById("dialog").style.display="block"
      document.getElementById("products").style.opacity=0.2
      document.getElementById("prodandcat").style.opacity=0.2
     
      document.body.style.overflow="hidden"
     
      setTimeout(()=>{
        document.getElementById("dialog").style.opacity=1
      },100)
      
  
  
  
  }
   
})
    
   let elem = document.querySelectorAll('.CarouselProduct');
 
   
    let index = 0;

    prev.onclick=function(){
        
        index = index - 4;
        index = Math.min(Math.max(index,0), elem.length-1);
       
        elem[index].scrollIntoView({behavior: 'smooth',block:'nearest'});
    }
    next.onclick=function(){
        
        index = index + 4;
        index = Math.min(Math.max(index,0), elem.length-1);
       
        elem[index].scrollIntoView({behavior: 'smooth',block:'nearest'});
    }
    
}



  if(show==1){
    createShowroom()
  }
  else{
      
  }

  





},[])


  
  
  return prods.map((item,index)=>{
    return (

      
    
      <div className="Product" key={index} id={"prod"+index}>
    <div className="Sponsor" id={index}>Gesponsert</div> 
        <div className="ProductImg"><img src={item.url}/></div>
        <div className="ProductName">{item.text}</div>
        <div className="ProductFoot">
            
        <div className="Favorite">
            <img src={Herz}/>
        </div>
        <div className="Buy ad_klicks"  data-click-tracking={item.name} data-proceed-questionnaire="false">
        <img src={Wagen}/>
        <span style={{fontSize:'30px'}}>+</span>
        </div>
    
         </div>


    </div>

      

    
   
   );
  })

   


 
 
}

export default ProductsDove;
