import {React, useEffect, useState, Component} from "react";
import './styles.scss'
import Logo from '../pict/logo.png'
import Net from '../pict/net.png'
import Flag from '../pict/flag.png'


function Footer(){
 
  const arrLinks1=["Karriere bei"," Pressemitteilungen", "Erfahre mehr über Amazon", "Impressum", "Amazon Science"]
  const arrLinks2=["Jetzt verkaufen", "Verkaufen bei Amazon Business","Verkaufen bei Amazon Handmade","Partnerprogramm","Versand durch Amazon","Deine Marke aufbauen und schützen","Prime durch Verkäufer","Bewerbe deine Produkte","Dein Buch mit uns veröffentlichen","Amazon Pay","Hosten eines Amazon Hubs"]
  const arrLinks3=[ "Einkaufen mit Punkten","Finanzierung von Barclays","Amazon Business Amex Card","Gutscheine","Monatsabrechnung","Bankenzahlung","Mein Amazon-Konto aufladen","Amazons– Währungsrechner","Mein Konto vor Ort aufladen"]
  const arrLinks4=["Versand & Verfügbarkeit","Rückgabe & Ersatz","Recycling (einschließlich Entsorgung von Elektro-& Elektronikaltgeräten)","Vodafone–Verträge kündigen","Meine Inhalte und Geräte","Rückrufe und Warnmeldungen zur Produktsicherheit","Kundenservice","Barrierefreiheit"]

    return(
        <div className="prime-footer">
          
          <div className="prime-footer-backbtn">Zurück zum Seitenanfang</div>
          
          <div className="prime-footer-block">

         


        <div className="prime-footer-textblock">

          <div className="prime-footer-text">
          <div className="prime-footer-text-head">Über Amazon</div>
          <div className="prime-footer-text_container">
          <FooterLinks arr={arrLinks1}/>
          </div>
          </div>

          <div className="prime-footer-text">
          <div className="prime-footer-text-head">Geld verdienen mit Amazon</div>
          <div className="prime-footer-text_container">
          <FooterLinks arr={arrLinks2}/>
          </div>
          </div>

          <div className="prime-footer-text">
          <div className="prime-footer-text-head">Amazon-Zahlungsarten</div>
          <div className="prime-footer-text_container">
          <FooterLinks arr={arrLinks3}/>
          </div>
          </div>

          <div className="prime-footer-text">
          <div className="prime-footer-text-head">Wir helfen dir</div>
          <div className="prime-footer-text_container">
          <FooterLinks arr={arrLinks4}/>
          </div>
          </div>

          </div>

          
         
          </div>

          <div className="prime-footer-block-logo">
            <img className="prime-footer-block-logo-img" src={Logo}/>
            <div className="prime-footer-block-logo-language"><img id="footer-logo-net" src={Net}/>Deutsch</div>
            <div className="prime-footer-block-logo-language"><img id="footer-logo-flag" src={Flag}/>Deutschland</div>
          </div>

          <div className="prime-footer-block-end">

          <div className="prime-footer-text-block-end">
          <div className="prime-footer-text-head-block-end">Amazon Advertising</div>
          <div className="prime-footer-text-end">Kunden finden,gewinnen und binden</div>
          
          
          </div>
          <div className="prime-footer-text-block-end">
          <div className="prime-footer-text-head-block-end">Amazon Music</div>
          <div className="prime-footer-text-end">Streame Millionen von Songs</div>
        
          
          </div>
          <div className="prime-footer-text-block-end">
          <div className="prime-footer-text-head-block-end">AbeBooks</div>
          <div className="prime-footer-text-end">Bücher, Kunst & Sammelobjekte</div>
        
          
          </div>
          <div className="prime-footer-text-block-end">
          <div className="prime-footer-text-head-block-end">Amazon Web Services</div>
          <div className="prime-footer-text-end">Cloud Computing Dienste von Amazon</div>
      
          
          </div>
          <div className="prime-footer-text-block-end">
          <div className="prime-footer-text-head-block-end">Audible</div>
          <div className="prime-footer-text-end">Hörbücher herunterladen</div>

          
          </div>

          </div>

        </div>
    )


    function FooterLinks(props){
      const arr=props.arr
      return(
      arr.map((item,index)=>{
        return(
          <div key={index}>{item}</div>
        )
      })
      )

    }

}

export default Footer;