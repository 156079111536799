import {React, useEffect, useState, Component, useRef} from "react";
import './indexstyle.scss'

import { Outlet, Link } from 'react-router-dom';



function Index(){



  useEffect(()=>{
   
  },[])


    return(
      <>
        <div className="tv_index-container">
        
        <Outlet/>

        </div>
    
        </>
    )
}

export default Index;