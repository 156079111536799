import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import '../../BodyStyle/styles.scss'
import data from '../../../data/8x4.json'
import Herz from '../../../Images/img/herz.png'
import Wagen from '../../../Images/img/einkaufswagen.png'


function Products() {

  let prods=data.products;
  console.log(prods)
  
  return prods.map((item,index)=>{
    return (

    
      <div className="Product" key={index} >
    <div className="Sponsor" id={index}>Gesponsert</div> 
        <div className="ProductImg"><img src={item.url}/></div>
        <div className="ProductName">{item.text}</div>
        <div className="ProductFoot">
            
        <div className="Favorite">
            <img src={Herz}/>
        </div>
        <div className="Buy">
        <img src={Wagen}/>
        <span style={{fontSize:'30px'}}>+</span>
        </div>
    
         </div>


    </div>

    
   
   );
  })

   


 
 
}

export default Products;
