import {React, useEffect, useState, Component, useRef} from "react";
import '../styles.scss'
import { register } from 'swiper/element/bundle';
import Data from '../../../../data/netfl/content1.json'
import FirstSlide from '../../pict/1.jpg'
import {useParams, useSearchParams  } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPlayer from "../Video/VideoPlayer";
import { Link, useNavigate, redirect } from "react-router-dom";
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import { click } from "@testing-library/user-event/dist/click";



register();
function Content({contentData, title, classIndex}){

  const navigate=useNavigate()

  // get video ad mode

  const [searchParams] = useSearchParams();
  const adMode = searchParams.get('m');
  const ad1 = searchParams.get('ad1');
  const ad2 = searchParams.get('ad2');
  const ad3 = searchParams.get('ad3');



    const handleScale=(e)=>{
      e.currentTarget.style.zIndex=1
    
   
     
      
    }
    const handleDescale=(e)=>{
        e.currentTarget.style.zIndex=0
     
    }
   

     const hanldeRedirectToVideo = (e) => {
    if (e.currentTarget) {
        let redirectUrl=e.currentTarget.getAttribute('data-redirect')
       // document.getElementById("Prime-main").className="PrimeMain-redirect"
       if(redirectUrl!=null){
      setTimeout(() => {
       navigate(redirectUrl) 
      },0); 
    }
    }
}

     useEffect(()=>{
      
        const nonclickItems=document.querySelectorAll('.primenone')
        nonclickItems.forEach((item,index)=>{
            delete item.dataset.redirect;
        })
           
     },[])

    

    return(
        <div className={"prime-content"+ " "+ classIndex} onMouseOver={handleScale} onMouseOut={handleDescale}>

    

<div className="prime-content-title">{title} <span style={{marginLeft:'2vw', fontWeight:'600'}}>Mehr</span></div>


<div>
<swiper-container className="prime-content-videos" id="primecontentvideos" slides-per-view="5" slides-per-group="5" navigation="true" space-between="25" loop="true">

    
   <ContentVid/>
  </swiper-container>

  </div>
 
        </div>
    )


    function ContentVid(){

        return contentData.content.map((item, index)=>{
            return(
                
                <swiper-slide lazy="true" key={index} id={"prime-"+index+"-slide"}>
                <div className={"prime-slide-block " + "slide-"+item.click} id={"prime-"+index+"-block"}>  
             <img id={"prime-"+index+"-videoImg"}  className={item.click} src={item.url} data-redirect={"watch/"+index+"?m="+adMode+"&ad1="+ad1+"&ad2="+ad2+"&ad3="+ad3} onClick={hanldeRedirectToVideo}/>
                
                <div className="prime-slide-menu">
                <div className="prime-slide-menu-text">
                    <span>{item.name}</span>
                    </div>   
                <div className="prime-slide-menu-playBtn_container"><div className="prime-slide-menu-playBtn"><svg className="prime-play_svg" style={{marginRight:'15px'}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-name="Play" aria-hidden="true"><path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="white"></path></svg>Abspielen</div></div>

                 <div className="prime-slide-menu-btns">
                    <div className="prime-slide-menu-btns-btn primeplus">
                    <AddIcon style={{color:'white', fontSize:30}}/>
                 </div>


                 <div className="prime-slide-menu-btns-btn primeplus">
                    <SlideshowOutlinedIcon style={{color:'white', fontSize:25}}/>
                 </div>

                 <div className="prime-slide-menu-btns-btn netlflike">
                 <DoNotDisturbAltOutlinedIcon style={{color:'white', fontSize:25}}/>
                 </div>


              
                </div>   
                 
                
                </div>
                </div>
                </swiper-slide> 
              
                
            )
        })
        
    }


}

export default Content;
