import {React, useEffect, useState, Component, useRef, Fragment} from "react";
import '../styles.scss'
import { register } from 'swiper/element/bundle';
import {useParams, useSearchParams  } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TikTokFooter from "../../Foot/TikTokFooter";
import { Link, useNavigate, redirect } from "react-router-dom";
import Data from '../../../../data/tiktok/data.json'
import AdData from '../../../../data/tiktok/ads.json'

// Icons

import FavoriteIcon from '@mui/icons-material/Favorite';
import Comments from './assets/icons/content_icons/comment.svg'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import TextsmsIcon from '@mui/icons-material/Textsms';

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import dummy from './assets/icons/noimage.png'

register();
function TiktokInFeed(){

  const navigate=useNavigate()

    const timeLine=useRef(0)
    const dialogF=useRef(0)
    const {tiktokad}=useParams()
    const videoLoader=useRef(null)
    const [searchParams] = useSearchParams();
    const tiktokad2 = searchParams.get('ad');


    const videoEvents = [
     
    'play', 'pause','waiting',
      'playing', 'canplay', 'canplaythrough', 
   
    ];
    
    function PlayVideos(){
        const content=document.querySelectorAll('.tiktok_contentVideo')
        let prevVideoContent=null
        const swiperEl = document.querySelector('swiper-container');

     
        const callback=function(entries, observer){ 
                    
            entries.forEach(entry=>{
             
              let {target}=entry
        
             
              prevVideoContent=target
             
              if(prevVideoContent!=null){
               
               
                if(prevVideoContent.currentTime > 0 && !prevVideoContent.paused){
                  prevVideoContent.pause()
                }
                  

                
                
               
              }
              
              if(entry.isIntersecting && entry.intersectionRatio>=0.5){
               
                target.load()
                target.muted=false
               
                target.currentTime=0
                target.play()
              
                contentTimeUpdate(target)

                target.addEventListener('waiting',showLoader)
                target.addEventListener('canplay',hideLoader)
            
                target.currentTime=0
              
                content.forEach((item,index)=>{
                  
                 if(index!=swiperEl.swiper.activeIndex){
                  item.muted=true
                  
                 }
                 
              })
                
            
                
              }
            
              
            })
           
          }

          
        const options={

            threshold: 0.6,
           
            }

        const observer=new IntersectionObserver(callback, options)
        
        content.forEach((item)=>{
            item.muted=true
            //item.play()
            //item.pause()
            observer.observe(item)
           

           
        })
    } 



    const showLoader=()=>{
   
      videoLoader.current.style.display="flex"

     
    
    }
      
    const hideLoader=()=>{
     videoLoader.current.style.display="none"
  
      
     
    }

   
    function contentTimeUpdate(item){
       let time=0;
       let videoDuration=0
     
       const videoTime=timeLine.current
       
       
       item.addEventListener('timeupdate', function(){
       
         videoDuration=Math.round(item.duration)
         time=Math.round(item.currentTime)
       
      
         videoTime.style.width=(time*100)/videoDuration + "%"
         if(time>=videoDuration){
           item.currentTime=0
           videoTime.style.width="0%"
           item.play()
          
         }
        })
    }


     useEffect(()=>{
      
        const swiperEl = document.querySelector('swiper-container');
        document.body.style.overflow = 'hidden';
        
        
        swiperEl.swiper.on('reachEnd',()=>{
          swiperEl.swiper.allowSlideNext=false
        })
    
        swiperEl.swiper.on('fromEdge', () => {
         
          swiperEl.swiper.allowSlideNext = true; 
        });

        
           
     },[])

    const launch=()=>{
      
        document.getElementById("tiktok-playContent-Dialog").style.display="none"
      document.body.style.overflow = 'auto';
      PlayVideos()
    }

    return(
      <>
      <div className="tiktok-playContent-dialog" id="tiktok-playContent-Dialog">
      <div className="tiktok-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="tiktok-playContent_btn-container"><div className="tiktok-playContent_btn" onClick={launch}>Weiter</div></div>
    </div>

    <Box className="tiktok_video_loader" ref={videoLoader}>
      <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
    </Box>

        <div className="tiktok-content">

        

            <swiper-container prevent-interaction-on-transition="true" direction="vertical"  slides-per-view="1" style={{height:'100dvh'}}>
         <ContentVid/>
          </swiper-container>
       
          <div className="tiktok_video-content_timeline-container"><div className='tiktok_video-content_timeline' ref={timeLine}></div></div>
          
        </div>
       
        </>
    )


    function ContentVid(){

        
       return Data.data.map((item,index)=>{

        if(index==2){
          return(
            <Fragment key={index}>
            <swiper-slide >
            <div className="tiktok-top_view">
        <div className="tiktok-top_view-contaniner">
        <div className="tiktok-top_view_video-container">
          <video preload="none" className="tiktok_contentVideo" src={AdData.ads[tiktokad].url} playsInline/>
        </div>
        <div className="tiktok_contentVideo_btns-container" >
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={AdData.ads[tiktokad].icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:1}}/><div>{AdData.ads[0].likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:1}}/>{AdData.ads[0].com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:1}}/>{AdData.ads[0].book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:1, transform:'scaleX(-1)'}}/>{AdData.ads[0].rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={AdData.ads[tiktokad].icons}/></div></div>

            </div>
       
        </div>
        
          
        </div>
            </swiper-slide>


            <swiper-slide >
            <div className="tiktok_video-content">
            <video preload="none" className="tiktok_contentVideo" src={item._attributes.url} playsInline/>
          
            <div className="tiktok_contentVideo_btns-container">
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={item._attributes.icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:0.9}}/><div>{item._attributes.likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:0.9, transform:'scaleX(-1)'}}/>{item._attributes.rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={item._attributes.icons}/></div></div>

            </div>
           

            </div>
            </swiper-slide>

            </Fragment>
          )
        }
        if(index==5){
          return(
            <Fragment key={index}>
            <swiper-slide >
            <div className="tiktok-top_view">
        <div className="tiktok-top_view-contaniner">
        <div className="tiktok-top_view_video-container">
          <video preload="none" className="tiktok_contentVideo" src={AdData.ads[tiktokad2].url} playsInline/>
        </div>
        <div className="tiktok_contentVideo_btns-container" >
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={AdData.ads[tiktokad2].icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:1}}/><div>{AdData.ads[0].likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:1}}/>{AdData.ads[0].com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:1}}/>{AdData.ads[0].book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:1, transform:'scaleX(-1)'}}/>{AdData.ads[0].rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={AdData.ads[tiktokad2].icons}/></div></div>

            </div>
       
        </div>
        
          
        </div>
            </swiper-slide>


            <swiper-slide >
            <div className="tiktok_video-content">
            <video preload="none" className="tiktok_contentVideo" src={item._attributes.url} playsInline/>
          
            <div className="tiktok_contentVideo_btns-container">
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={item._attributes.icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:0.9}}/><div>{item._attributes.likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:0.9, transform:'scaleX(-1)'}}/>{item._attributes.rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={item._attributes.icons}/></div></div>

            </div>
           

            </div>
            </swiper-slide>

            </Fragment>
          )
        }
        else{

        return(
            <Fragment key={index}>
            <swiper-slide >
            <div className="tiktok_video-content">
            <video preload="none" className="tiktok_contentVideo" src={item._attributes.url} playsInline/>
          
            <div className="tiktok_contentVideo_btns-container">
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle"><img src={item._attributes.icons}/><div className="tiktok_contentVideo_circle-plus">+</div></div></div>
            <div className="tiktok_contentVideo_btn"><FavoriteIcon sx={{fontSize:30, color:'white', opacity:0.9}}/><div>{item._attributes.likes}</div></div>
            <div className="tiktok_contentVideo_btn"><TextsmsIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.com}</div>
            <div className="tiktok_contentVideo_btn"><BookmarkIcon sx={{fontSize:30, color:'white', opacity:0.9}}/>{item._attributes.book}</div>
            <div className="tiktok_contentVideo_btn"><ReplyIcon sx={{fontSize:30, color:'white', opacity:0.9, transform:'scaleX(-1)'}}/>{item._attributes.rep}</div>
            <div className="tiktok_contentVideo_btn"><div className="tiktok_contentVideo_circle" id="tiktok_contentVideo_circle_spin"><img src={item._attributes.icons}/></div></div>

            </div>
           

            </div>
            </swiper-slide>
            </Fragment>
        )

      }
       })
          
    }


}

export default TiktokInFeed;
