import {React, useEffect, useState, Component, useRef} from "react";
import './styles.scss'
import { register } from 'swiper/element/bundle';
import Data from '../../../../data/netfl/content1.json'
import FirstSlide from '../../pict/1.jpg'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';



register();
function ContentDumm({contentData, title, classIndex}){

   

    const handleScale=(e)=>{
      e.currentTarget.style.zIndex=1
    
   
     
      
    }
    const handleDescale=(e)=>{
        e.currentTarget.style.zIndex=0

    }
   
    

     useEffect(()=>{
      
       
           
     },[])

    

    return(
        <div className="prime-content PrimeDumm" onMouseOver={handleScale} onMouseOut={handleDescale}>

           

<div className="prime-content-title">{title}</div>


<div>
<swiper-container className="prime-content-videos" id="primecontentvideos" slides-per-view="5" slides-per-group="5" navigation="true" space-between="25" loop="true">

    
   <ContentVid/>
  </swiper-container>

  </div>
 
        </div>
    )


    function ContentVid(){

        return contentData.content.map((item, index)=>{
            return(
                
                <swiper-slide key={index} id={"prime-"+index+"-slide"}>
                <div className="prime-slide-block" id={"prime-"+index+"-block"}>  
                <img id={"prime-"+index+"-videoImg"} src={item.url}/> 
                <div className="prime-slide-menu">
                <div className="prime-slide-menu-text">
         
         </div>   

                <div className="prime-slide-menu-playBtn_container"><div className="prime-slide-menu-playBtn"><svg style={{marginRight:'15px'}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true"><path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="white"></path></svg>Abspielen</div></div>

                <div className="prime-slide-menu-btns">
                    <div className="prime-slide-menu-btns-btn primeplus">
                    <AddIcon style={{color:'white', fontSize:30}}/>
                 </div>


                 <div className="prime-slide-menu-btns-btn primeplus">
                    <SlideshowOutlinedIcon style={{color:'white', fontSize:25}}/>
                 </div>

                 <div className="prime-slide-menu-btns-btn netlflike">
                 <DoNotDisturbAltOutlinedIcon style={{color:'white', fontSize:25}}/>
                 </div>

               

                </div>   
              
                
                </div>
                </div>
                </swiper-slide> 
              
                
            )
        })
        
    }


}

export default ContentDumm;
