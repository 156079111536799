import React,{useEffect, useState, Component} from 'react';

import '../../BodyStyle/styles.scss';


function Sortiment() {

  return (

<div>

<div className="Sortiment">
   
<div className="SortimentName">
       
       <div className="BackText">◄ Zurück <span>Drogerie & Kosmetik</span></div>
       <div id="headtext" style={{fontSize:'1em', fontWeight:'bold'}}>Drogerie & Kosmetik</div>
      
   </div>


   <div className="SortimentPage">
       <div className="ProductCount">Artikel pro Seite<div id="count">40</div>Sortieren<div id="sort">Relevanz</div></div>
   </div>

</div>

</div>


  );
}

export default Sortiment;
