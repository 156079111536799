import React,{useEffect, useState, Component} from 'react';
import Category from './Category';
import Products from './Products';
import Sortiment from './Sortiment';
import '../../BodyStyle/styles.scss'


function Body() {

useEffect(()=>{
    
   

      document.getElementById('1').style.display="block"
      document.getElementById('2').style.display="block"
      document.getElementById('4').style.display="block"
      document.getElementById('5').style.display="block"
      document.getElementById('6').style.display="block"
      document.getElementById('8').style.display="block"
      
    
},[])

  return (
    <div className="MainBody">
      <Sortiment/>
     <div className='ProductsandCategory'>
     <Category/>
     <div className="Products" id="products">
      <Products/>
      </div>
      
     </div>


</div>
  );
}

export default Body;
