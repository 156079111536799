import {React, useEffect, useState, Component, useRef} from "react";
import '../../styles.scss'
import PowerBtn from '../assets/power.svg'
import Data from '../../../../data/tv/data.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams  } from 'react-router-dom';
import Ads from '../../../../data/tv/ads.json'
import BlackScreen from '../TvBlackScreen'

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function ETvspot(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const tvad = searchParams.get('tvad');

  const sidebump=useRef(0)
  const footbump=useRef(0)
  const videoContainer=useRef(0)
  const videoAdContainer=useRef(0)
  const videoAd=useRef(0)
  const videoContent=useRef(0)
  const videoAdCount=useRef(0)
  const blackScreen=useRef(0)
  const videoLoader=useRef(null)
  const advideoLoader=useRef(null)

  const[adCount, setAdCount]=useState(0)

  const[Contentlaunch, setContentlaunch]=useState(false)

  useEffect(()=>{

  },[])

  const launch=(e)=>{
    if(Contentlaunch==false){
    setTimeout(() => {
      blackScreen.current.style.visibility="hidden"
      e.target.play()
    }, 1500);
    setTimeout(() => {
      blackScreen.current.style.display="none"
      setContentlaunch(!Contentlaunch)
    }, 2500);
  }
  hideLoader()
  }

  const tvTime=(e)=>{
    if(Math.floor(e.target.currentTime)==2){
      adOn(startAd)
    }

  }

  const adTime=(e)=>{
   
      setAdCount(Math.round(e.target.duration-e.target.currentTime))

  }

  const adEnd=()=>{
    adOff()
  }


  const adOn=(callback)=>{
    hideLoader()
    sidebump.current.style.width="20%"  
    footbump.current.style.width="80%"  
    footbump.current.style.height="20%"  
    videoContainer.current.style.width="80%"  
    videoContainer.current.style.height="80%"  

    
    callback()

  }

  const startAd=()=>{
    setTimeout(() => {
      
    
    videoContent.current.pause()
    hideLoader()
    videoAdContainer.current.style.display="block"
    videoAd.current.play()
    videoAdCount.current.style.opacity=1
    setAdCount(Math.round(videoAd.current.duration-videoAd.current.currentTime))
  }, 1500);
  }


  const adOff=()=>{
   
    videoAdContainer.current.style.display="none"
    sidebump.current.style.width="0%"  
    footbump.current.style.width="100%"  
    footbump.current.style.height="0%"  
    videoContainer.current.style.width="100%"  
    videoContainer.current.style.height="100%"  
    videoContent.current.play()
   
  }

  const showLoader=()=>{
   
    videoLoader.current.style.display="flex"
  
  }
    
  const hideLoader=()=>{
   videoLoader.current.style.display="none" 
   
  }
  const showAdLoader=()=>{
   
    advideoLoader.current.style.display="flex"
  
  }
    
  const hideAdLoader=()=>{
   advideoLoader.current.style.display="none" 
   
  }

  


    return(
      <>
        <div className="tv_etv_index-container">

        <div className="tv_etv_spot_content-container">
          <div className="tv_etv_spot_side-bumper" ref={sidebump}>
            <div className="tv_etv_spot_side-bumper_ad-contdown" ref={videoAdCount}>{adCount}</div>
            <div className="tv_etv_spot_side-bumper_text">Werbung</div>
            <img src={Ads.ads[tvad].sidebumper}/>
          </div>
           <div className="tv_etv_spot_video-container" ref={videoContainer}>
            <div className="tv_etv_spot-blackscreen_container" ref={blackScreen}>
            <BlackScreen />
            </div>
            <Box className="tv_video_loader" ref={videoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoContent} playsInline src={Data.data[tv]._attributes.url} onTimeUpdate={tvTime} onWaiting={showLoader} onCanPlay={launch}></video>
           <div ref={videoAdContainer} className="tv_etv_spot_videoad-container" >
           <Box className="tv_video_loader" ref={advideoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoAd} playsInline src={Ads.ads[tvad].url} onTimeUpdate={adTime} onEnded={adEnd} onWaiting={showAdLoader} onCanPlay={hideAdLoader}></video>
           </div>
           </div>
           <div className="tv_etv_spot_foot-bumper" ref={footbump}>
          
           <img src={Ads.ads[tvad].footbumper}/>
           </div>
        </div>


        </div>
    
        </>
    )
}

export default ETvspot;