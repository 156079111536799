import {React, useEffect, useState, Component, useRef} from "react";
import Head from './Head/HeadPrime'
import MainPic from './Components/MainPic/Body'
import Content from './Components/Content/Content'
import ContentDumm from './Components/Content/ContentDumm'
import Footer from './Foot/Footer'
import './indexstyle.scss'
import ContentData1 from '../../data/prime/content1.json'
import ContentData2 from '../../data/prime/content2.json'
import ContentData3 from '../../data/prime/content3.json'
import ContentData4 from '../../data/prime/content4.json'
import ContentData5 from '../../data/prime/content5.json'
import ContentData6 from '../../data/prime/content6.json'
import ContentData7 from '../../data/prime/content7.json'

import PrimeLogo from './pict/Logo-min.png'
import img_profile from './pict/acc.png'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AppsIcon from '@mui/icons-material/Apps';

function Index(){

const head=useRef(null)
const headLinks=useRef(null)

useEffect(()=>{
   
    window.addEventListener('scroll', function(){
       
        if(head.current!=null){
        if(window.scrollY>0){
    head.current.style.background='transparent'
    headLinks.current.style.background='rgba(25,30,37,.8)'
        }
   
        if(window.scrollY==0){
   head.current.style.background='#00050d'
    headLinks.current.style.background='#00050d'
        }
    }
    
     })
  
  },[])


    return(

    
        <div className="PrimeMain" id="Prime-main">
            <Head/>
            <div className="PrimeMain_container">
            <PrimeVideoNav/>
                <div className="gg">
          
            <MainPic/>
            </div>
            <Content contentData={ContentData1} title="Beliebte Filme und Serien" classIndex="content1"/>

            <ContentDumm contentData={ContentData5} title="Empfehlungen für dich"/>
            

            <ContentDumm contentData={ContentData3} title="Aktuell angesagte Filme"/>

            <ContentDumm contentData={ContentData4} title="Beliebt"/>

            <ContentDumm contentData={ContentData2} title="Fortsetzen"/> 

            <ContentDumm contentData={ContentData6} title="Freevee: Kürzlich hinzugefügte Serien & Filme"/>

            <ContentDumm contentData={ContentData7} title="Mehr Entdecken"/> 

           <Footer/>

            </div>
            {/* 
            
            
            
           */}
            
        </div>
    )


    function PrimeVideoNav(){
        return(
            <div ref={head} className="prime-head-video-nav">

            <div ref={headLinks} className="prime-head-video-nav-links">
                <div id="primelogo"><img src={PrimeLogo}/></div>
                <div className="prime-video-nav-links_container">
                <div className="prime-video-nav-links primeNavChecked"><span>Startseite</span></div>
                <div className="prime-video-nav-links">Filme</div>
                <div className="prime-video-nav-links">Serien</div>
                <div className="prime-video-nav-links">Sport</div>
                <div className="prime-video-nav-links">Live-TV</div>
                <div className="prime-video-nav-links">Werbefrei</div>
                </div>
                <div className="prime-video-nav-links primeAcc"><div><AppsIcon/></div><div><BookmarkBorderOutlinedIcon/></div> <img src={img_profile}/></div>
            </div>

            
            
            </div>
        )
    }



}

export default Index;