import {React, useEffect, useState, Component, useRef} from "react";
import './indexstyle.scss'
import Head from './Head/HeadTikTok'
import TiktokContent from "./Components/Content/TiktokContent";
import TikTokFooter from "./Foot/TikTokFooter";
import { Outlet, Link } from 'react-router-dom';



function Index(){



  useEffect(()=>{
   
  },[])


    return(
      <>
        <div className="tik_tok-index" id="tiktokindex">
   
          <Head/>
       
        <Outlet/>
     
        <TikTokFooter/>
        </div>
    
        </>
    )
}

export default Index;